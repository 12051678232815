@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p{
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.navbar-toggler{
  border: none !important;
  outline: none !important;      
  box-shadow: none !important; 
}
.navbar-toggler:focus{
 border: none !important;
 outline: none !important;     
 box-shadow: none !important; 
}
.card-listing{
  width: 30rem;
}


@media screen and (max-width: 1440px) {
  .card-listing{
    width: 28rem;
  }
}

@media screen and (max-width: 1024px) {
  .card-listing{
    width: 20rem;
  }
}



