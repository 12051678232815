
    .responsive-map iframe{
    height:60vh;
    width:100%;
    }
    .detail{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
.detail button{
  border: none;
  outline: none;
  background: none;
  font-size: 18px;
  font-weight: 500;
  padding: 0px 30px;
}
.detail-bottom button{
  width: 30%;
}
.detail-bottom1{
  width: 30%;
}

@media screen and (max-width: 425px) {
  .detail-bottom button{
    width: 100%;
  }
  .detail-bottom1{
    width: 100%;
  }
  .detail-bottom1 p{
    padding: 0px 25px;
  }
  
}