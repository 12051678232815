.home .row {
  height: 100vh;
}
.home h1 {
  font-size: 50px;
  color: white;
  font-weight: bold;
  line-height: 80px;
}
.home p {
  color: white;
  font-size: 28px;
}

#search-wrapper {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.276);
  align-items: stretch;
  border-radius: 12px;
  background-color: #ffffff;
  overflow: hidden;
  max-width: 500px;
  justify-content: center;
  align-items: center;
}
#search {
  border: none;
  width: 500px;
  font-size: 15px;
  height: 45px;
}
#search:focus {
  outline: none;
}
.search-icon {
  margin: 10px;
  color: rgba(0, 0, 0, 0.564);
}
#search-button {
  border: none;
  cursor: pointer;
  color: #fff;
  background-color: #1dbf73;
  padding: 0px 10px;
}

.home button {
  background-color: #d0103a;
  color: #fff;
  border: 1px solid #d0103a;
  padding: 6px 40px;
  border-radius: 12px;
  font-size: 22px;
}
.home .inputs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.home4 {
    background-image: url('/public/assets/Images/image_block.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 70vh;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
}
.home4 .col-lg-4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60vh;
}
.home4-page button {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    background-color: #d0103a;
    border: none;
    outline: none;
    padding: 10px 16px;
    width: 50%;
    border-radius: 25px;
}
@media screen and (max-width: 500px) {
  .home4-page button {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    background-color: #d0103a;
    border: none;
    outline: none;
    padding: 12px 10px;
    width: 65%;
    border-radius: 25px;
  }
  .home h1 {
    font-size: 30px;
    color: white;
    font-weight: bold;
    line-height: normal;
  }
  .home p {
    color: white;
    font-size: 20px;
  }
  #search-wrapper {
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.276);
    align-items: stretch;
    border-radius: 6px;
    background-color: #ffffff;
    overflow: hidden;
    max-width: 300px;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .home .inputs {
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .home button {
    background-color: #d0103a;
    color: #fff;
    border: 1px solid #d0103a;
    padding: 6px 50px;
    border-radius: 12px;
    font-size: 22px;
  }
}


@media screen and (max-width: 320px) {
  .home button {
    background-color: #d0103a;
    color: #fff;
    border: 1px solid #d0103a;
    padding: 6px 40px;
    border-radius: 12px;
    font-size: 22px;
  }
}
